import { Button, Form, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { FC, useEffect } from "react";
import {  UpdateUserModel, UserModel } from "../../../models/UserModel";
import UserDataFields from "./UserDataFields";


interface UserFormProps {
    user: UserModel | null;
    isLoading: boolean;
    onSubmitValues: ((data: UpdateUserModel) => void);
}

interface UserFormValues extends Omit<UpdateUserModel, 'id'> { }

const UpdateUserForm: FC<UserFormProps> = ({ isLoading, user, onSubmitValues }) => {
    const [form] = useForm<UserFormValues>();

    useEffect(() => {
        if (user) {
            form.setFieldsValue(user);
        }
    }, [user, form]);

    const onFinish = (values: UserFormValues) => {
        if (user) {
            const finalUser: UpdateUserModel = {
                ...values,
                id: user.id,
            }
            onSubmitValues(finalUser);
        }
    }

    return (
        <Spin spinning={isLoading}>
            <Form<UserFormValues>
                form={form}
                onFinish={onFinish}
                validateMessages={{
                    required: 'Обов’язкове поле!',
                }}
            >
                <UserDataFields disableEmail />
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Зберегти
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
}

export default UpdateUserForm;