import { Alert, Button, Card, Cascader, DatePicker, Form, Input, InputNumber, Radio, Select, Switch } from 'antd';
import TreeSelect from 'rc-tree-select';
import { FC, useState } from 'react';

type SizeType = Parameters<typeof Form>[0]['size'];

const Dashboard: FC = () => {
    const [componentSize, setComponentSize] = useState<SizeType | 'default'>('default');

    const onFormLayoutChange = ({ size }: { size: SizeType }) => {
        setComponentSize(size);
    };

    return (
        <>
            <Card>
                <Form
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 14 }}
                    layout="horizontal"
                    initialValues={{ size: componentSize }}
                    onValuesChange={onFormLayoutChange}
                    size={componentSize as SizeType}>
                    <Form.Item label="Form Size" name="size">
                        <Radio.Group>
                            <Radio.Button value="small">Small</Radio.Button>
                            <Radio.Button value="default">Default</Radio.Button>
                            <Radio.Button value="large">Large</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="Input">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Select">
                        <Select>
                            <Select.Option value="demo">Demo</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="TreeSelect">
                        <TreeSelect
                            treeData={[
                                { title: 'Light', value: 'light', children: [{ title: 'Bamboo', value: 'bamboo' }] },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item label="Cascader">
                        <Cascader
                            options={[
                                {
                                    value: 'zhejiang',
                                    label: 'Zhejiang',
                                    children: [
                                        {
                                            value: 'hangzhou',
                                            label: 'Hangzhou',
                                        },
                                    ],
                                },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item label="DatePicker">
                        <DatePicker />
                    </Form.Item>
                    <Form.Item label="InputNumber">
                        <InputNumber />
                    </Form.Item>
                    <Form.Item label="Switch" valuePropName="checked">
                        <Switch />
                    </Form.Item>
                    <Form.Item label="Button">
                        <Button>Button</Button>
                    </Form.Item>
                </Form>
            </Card>
            <Card>
                <Alert message="Success Text" type="success" />
                <Alert message="Info Text" type="info" />
                <Alert message="Warning Text" type="warning" />
                <Alert message="Error Text" type="error" />
            </Card>
            <Card>
                <Button type="primary">Primary</Button>
                <Button type="primary" disabled>
                    Primary(disabled)
                </Button>
                <br />
                <Button>Default</Button>
                <Button disabled>Default(disabled)</Button>
                <br />
                <Button type="dashed">Dashed</Button>
                <Button type="dashed" disabled>
                    Dashed(disabled)
                </Button>
                <br />
                <Button type="text">Text</Button>
                <Button type="text" disabled>
                    Text(disabled)
                </Button>
                <br />
                <Button type="link">Link</Button>
                <Button type="link" disabled>
                    Link(disabled)
                </Button>
                <br />
                <Button danger>Danger Default</Button>
                <Button danger disabled>
                    Danger Default(disabled)
                </Button>
                <br />
                <Button danger type="text">
                    Danger Text
                </Button>
                <Button danger type="text" disabled>
                    Danger Text(disabled)
                </Button>
                <br />
                <Button type="link" danger>
                    Danger Link
                </Button>
                <Button type="link" danger disabled>
                    Danger Link(disabled)
                </Button>
                <div className="site-button-ghost-wrapper">
                    <Button ghost>Ghost</Button>
                    <Button ghost disabled>
                        Ghost(disabled)
                    </Button>
                </div>
            </Card>
        </>
    );
};

export default Dashboard;
