import { createApi } from '@reduxjs/toolkit/query/react';
import { UserModel } from '../../models/UserModel';
import buildQuery, { QueryOptions } from 'odata-query';
import customBaseQuery from './common/customBaseQuery';

type GetUsersResponse = {
  count: number;
  items: UserModel[]
}

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    getUser: builder.query<UserModel, string | number>({
      query(requestId) {
        return {
          url: `user/${requestId}`,
          method: 'GET',
        };
      },
    }),
    getUsers: builder.query<GetUsersResponse, Partial<QueryOptions<UserModel>>>({
      query(queryData) {
        const queryString = buildQuery(queryData);
        return {
          url: `user${queryString}`,
          method: 'GET',
        };
      }, 
    }),
    updateUser: builder.mutation<UserModel, any>({
      query(data) {
        return {
          url: `user/${data.id}`,
          method: 'PUT',
          body: data
        };
      },
    }),
    createUser: builder.mutation<UserModel, any>({
      query(data) {
        return {
          url: `user`,
          method: 'POST',
          body: data
        };
      },
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useUpdateUserMutation,
  useCreateUserMutation,
} = usersApi;