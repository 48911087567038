import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import FormPageLayout from '../../components/common/FormPageLayout';
import RequestForm from '../../components/forms/requst-form/RequestForm';
import { CreateUpdateRequestModel } from '../../models/RequestModel';
import { useCreateRequestMutation } from '../../redux/api/requestApi';

const CreateRequest: FC = () => {
    const [createRequest, { isLoading }] = useCreateRequestMutation();

    const navigate = useNavigate();

    const handleSubmit = async (data: CreateUpdateRequestModel) => {
        const request = await createRequest(data).unwrap();
        if (request.id) {
            navigate(`/registers/${request.id}`);
        }
    }

    return (
        <FormPageLayout backButtonLabel="Реєстр">
            <RequestForm request={null} isLoading={isLoading} onSubmitValues={handleSubmit} />
        </FormPageLayout>
    );
};

export default CreateRequest;
