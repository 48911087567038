import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import FormPageLayout from '../../components/common/FormPageLayout';
import CreateUserForm from '../../components/forms/user-form/CreateUserForm';
import { CreateUserModel } from '../../models/UserModel';
import { useCreateUserMutation } from '../../redux/api/usersApi';

const CreateUser: FC = () => {
    const [createUser, { isLoading }] = useCreateUserMutation();

    const navigate = useNavigate();

    const handleSubmit = async (data: CreateUserModel) => {
        const request = await createUser(data).unwrap();
        if (request.id) {
            navigate(`/users/${request.id}`);
        }
    }

    return (
        <FormPageLayout backButtonLabel="Користувачі">
            <CreateUserForm isLoading={isLoading} onSubmitValues={handleSubmit} />
        </FormPageLayout>
    );
};

export default CreateUser;
