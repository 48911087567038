import { Form, Input, Select} from "antd";
import { FC } from "react";
import { TerritoryUnitModel } from "../../../models/TerritoryUnitModel";

interface LocationFieldsProps {
    rays?: TerritoryUnitModel[];
    communities?: TerritoryUnitModel[];
    cities?: TerritoryUnitModel[];
    handleRaySelect: (unit: TerritoryUnitModel['id']) => void;
    handleCommunitySelect: (unit: TerritoryUnitModel['id']) => void;
}

const getSelectOptionFromTerritoryUnit = (item: TerritoryUnitModel) => <Select.Option key={item.id} value={item.id}>{item.short_name}</Select.Option>

export const LocationFields: FC<LocationFieldsProps>= ({ 
    rays, 
    communities,
    cities,
    handleRaySelect,
    handleCommunitySelect,
}) => {
    return (
        <>
            <Form.Item>
                    <h3>Розташування об’єкта</h3>
                </Form.Item>
                <Form.Item name="ray" rules={[{ required: true }]}>
                    <Select onChange={handleRaySelect} placeholder="Район">
                        {rays?.map(getSelectOptionFromTerritoryUnit)}
                    </Select>
                </Form.Item>
                <Form.Item name="community" rules={[{ required: true }]}>
                    <Select onChange={handleCommunitySelect} placeholder="Громада">
                        {communities?.map(getSelectOptionFromTerritoryUnit)}
                    </Select>
                </Form.Item>
                <Form.Item name="city" rules={[{ required: true }]}>
                    <Select placeholder="Місто">
                        {cities?.map(getSelectOptionFromTerritoryUnit)}
                    </Select>
                </Form.Item>
                <Form.Item name="street" rules={[{ required: true }]}>
                    <Input placeholder="Вулиця" />
                </Form.Item>
                <Form.Item name="building" rules={[{ required: true }]}>
                    <Input placeholder="Будинок" />
                </Form.Item>
                <Form.Item>
                    <h3>Місце розташування об’єкта</h3>
                </Form.Item>
                <Form.Item name="addressLink" rules={[{ required: true }]}>
                    <Input placeholder="Посилання на гугл карту з міткою/маркером " />
                </Form.Item>
        </>
    )
}