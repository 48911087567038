import { Card, Layout, Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import RouteList, { menuIcons } from '../../constants/enums/RouterList';
import { RouterListModel } from '../../models/RouterListModel';
import Header from './Header';
import Routers from './Routers';

const { Content, Footer, Sider } = Layout;

const menuItems: ItemType[] = RouteList.filter(route => !!route.icon).map((route: RouterListModel) => ({
    key: route.code,
    icon: route.icon && menuIcons[route.icon],
    label: route.name,
} as ItemType));

const MainLayout: FC = () => {
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate();

    const handleMenuSelect = ({ key }: any) => {
        const route = RouteList.find((item: RouterListModel) => item.code === key);
        if (route) {
            navigate(route.path as any);
        }
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                <div className="logo">
                    <img src={logo} alt="logo" width={'40%'} />
                </div>
                <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={menuItems} onSelect={handleMenuSelect} />
            </Sider>
            <Layout className="site-layout">
                <Header className="site-layout-background" style={{ padding: 0 }} />
                <Content style={{ margin: '20px' }}>
                    <Card style={{ height: '100%' }}>
                        <Routers />
                    </Card>
                </Content>
                <Footer style={{ textAlign: 'center' }}>Портал ВК ©2022</Footer>
            </Layout>
        </Layout>
    );
};

export default MainLayout;
