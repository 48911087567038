import { DatePicker, Form, Input, UploadFile } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { FC } from "react";
import FileDraggerUpload from "../../common/FileDraggerUpload";
import { UploadFileWithId } from "./RequestForm";

interface TechnicalReportFieldsProps {
    fileList: UploadFileWithId[];
    onUploadChange: ((info: UploadChangeParam<UploadFile>) => void);
}


export const TechnicalReportFields: FC<TechnicalReportFieldsProps> = ({ fileList, onUploadChange }) => {
    return (
        <>
            <Form.Item>
                <h3>Інформація щодо документа про проведення обстеження (назва, реєстраційний номер, дата)</h3>
            </Form.Item>
            <Form.Item name="technicalReport" rules={[{ required: true }]} label="ТЕХНІЧНИЙ ЗВІТ No ">
                <Input />
            </Form.Item>
            <Form.Item name="technicalReportDate" rules={[{ required: true }]} label=" від ">
                <DatePicker />
            </Form.Item>
            <Form.Item name="technicalReportPerformer" rules={[{ required: true }]} label="Розробник: ">
                <Input placeholder="Назва організації-виконавця"/>
            </Form.Item>
             <Form.Item>
                <FileDraggerUpload fileList={fileList} onChange={onUploadChange}/>
            </Form.Item>
        </>
    )
}