import { Navigate, useLocation } from 'react-router-dom';
import { FC, PropsWithChildren } from 'react';
import { getUser } from '../../redux/features/authSlice';
import { useAppSelector } from '../../redux/hooks';



const PrivateRoute: FC<PropsWithChildren<{}>> = ({ children }) => {
    const user = useAppSelector(getUser);
    const location = useLocation();
    
    if (!user) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/login" state={{ from: location }} />
    }

    // // authorized so return child components
    return children as React.ReactElement;
}

export default PrivateRoute;