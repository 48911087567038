import { Checkbox, DatePicker, Form, Input, Radio, RadioChangeEvent, Select } from "antd";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import { FC } from "react";
import { ConstructionWorkStatuses } from "../../../constants/enums/ConstructionWorkStatuses";
import { DamageCategories } from "../../../constants/enums/DemageCategories";
import { LegalDocumentsType } from "../../../constants/enums/LegalDocumentsType";
import { SurveyActRecommendationCodes } from "../../../constants/enums/SurveyActRecommendationCodes";
import { CommonDictionaryModel } from "../../../models/CommonDictionaryModel";
import { TerritoryUnitModel } from "../../../models/TerritoryUnitModel";
import FileDraggerUpload from "../../common/FileDraggerUpload";
import { ApplicantsFields } from "./ApplicatnsFields";
import { LocationFields } from "./LocationFields";
import { getSelectOptionFromDictionary } from "./RequestForm";
import { SurveyActFields } from "./SurveyActFields";
import { TechnicalReportFields } from "./TechnicalReportFields";

interface IndividualBuildingFieldsProps {
    rays?: TerritoryUnitModel[];
    communities?: TerritoryUnitModel[];
    cities?: TerritoryUnitModel[];
    legalDocumentTypes?: CommonDictionaryModel<LegalDocumentsType>[];
    damageCategories?: CommonDictionaryModel<DamageCategories>[];
    surveyActRecommendations?: CommonDictionaryModel<SurveyActRecommendationCodes>[];
    constructionWorkStatuses?: CommonDictionaryModel<ConstructionWorkStatuses>[];
    needForBasicExamination?: boolean;
    handleRaySelect: (unit: TerritoryUnitModel['id']) => void;
    handleCommunitySelect: (unit: TerritoryUnitModel['id']) => void;
    handleNeedForBasicExaminationChange: (e: RadioChangeEvent) => void;
    technicalReportFiles:  UploadFile[];
    onTechnicalReportFilesUploadChange: (info: UploadChangeParam<UploadFile<any>>) => void;
    surveyActFiles:  UploadFile[];
    onSurveyActFilesUploadChange: (info: UploadChangeParam<UploadFile<any>>) => void;
    buildingFiles:  UploadFile[];
    onBuildingFilesUploadChange: (info: UploadChangeParam<UploadFile<any>>) => void;
}

export const IndividualBuildingFields: FC<IndividualBuildingFieldsProps> = ({
    rays,
    communities,
    cities,
    legalDocumentTypes,
    handleRaySelect,
    handleCommunitySelect,
    needForBasicExamination,
    handleNeedForBasicExaminationChange,
    damageCategories,
    surveyActRecommendations,
    constructionWorkStatuses,
    technicalReportFiles,
    onTechnicalReportFilesUploadChange,
    surveyActFiles,
    onSurveyActFilesUploadChange,
    buildingFiles,
    onBuildingFilesUploadChange,
}) => {
    return (
        <>
            <ApplicantsFields />
            <LocationFields 
                rays={rays}
                communities={communities}
                cities={cities}
                handleRaySelect={handleRaySelect}
                handleCommunitySelect={handleCommunitySelect}
            />
            <Form.Item name="legalDocumentsType" rules={[{ required: true }]} label="Наявність правовстановлюючих документів на пошкоджене/знищене майно:">
                <Select placeholder="Тип документу правовласності">
                    {legalDocumentTypes?.map(getSelectOptionFromDictionary)}
                </Select>
            </Form.Item>
            <Form.Item name="totalArea" rules={[{ required: true }]} label="Площа">
                    <Input placeholder="м2" />
                </Form.Item>
                <Form.Item name="additionalInfo" rules={[{ required: false }]} label="Додаткова інформація">
                    <Input.TextArea rows={3} />
                </Form.Item>
                <Form.Item label="Додати файл: ">
                    <FileDraggerUpload fileList={buildingFiles} onChange={onBuildingFilesUploadChange} />
                </Form.Item>
            <Form.Item name="needForBasicExamination" rules={[{ required: true }]} label="Інформація про необхідність основного обстеження:">
                <Radio.Group onChange={handleNeedForBasicExaminationChange}>
                    <Radio value={true}>Так</Radio>
                    <Radio value={false}>Ні</Radio>
                </Radio.Group>
            </Form.Item>
            {needForBasicExamination ?
                <TechnicalReportFields
                    fileList={technicalReportFiles}
                    onUploadChange={onTechnicalReportFilesUploadChange} 
                /> 
                :
                <SurveyActFields 
                    fileList={surveyActFiles}
                    onUploadChange={onSurveyActFilesUploadChange}
                />
            }
            <Form.Item name="damageCategory" rules={[{ required: true }]} label="Категорія пошкоджень відповідно до «Акту комісії»:">
                <Radio.Group>
                    {damageCategories?.map(category => <Radio value={category.code}>{category.translations[0].description}</Radio>)}
                </Radio.Group>
            </Form.Item>
            <Form.Item name="surveyActRecommendation" rules={[{ required: false }]} label="Рекомендовано відповідно до «Акту комісії» виконання робіт: ">
                <Select>
                    {surveyActRecommendations?.map(getSelectOptionFromDictionary)}
                </Select>
            </Form.Item>
            <Form.Item>
                <h3>Статус об’єкта для поточного ремонту</h3>
            </Form.Item>
            <Form.Item name="commissionAct" valuePropName="checked" rules={[{ required: false }]} label="Cкладено акт комісії: ">
                <Checkbox />
            </Form.Item>
            <Form.Item name="defectiveAct" valuePropName="checked" rules={[{ required: false }]} label="Складено дефекний акт: ">
                <Checkbox />
            </Form.Item>
            <Form.Item name="concludedContractForWork" valuePropName="checked" rules={[{ required: false }]} label="Заключений договір на виконання робіт: ">
                <Checkbox />
            </Form.Item>
            <Form.Item name="concludedContractEndWorkDate" rules={[{ required: false }]} label="Орієнтовні терміни завершення">
                <DatePicker />
            </Form.Item>
            <Form.Item name="costOfConstructionWorks" rules={[{ required: false }]} label="Вартість будівельних робіт, тис. грн">
                <Input />
            </Form.Item>
            <Form.Item name="constructionWorkStatus" rules={[{ required: false }]} label="Статус будівельних робіт: ">
                <Select>
                    {constructionWorkStatuses?.map(getSelectOptionFromDictionary)}
                </Select>
            </Form.Item>
            <Form.Item name="constructionWorkStartDate" rules={[{ required: false }]} label="Дата початку: ">
                <DatePicker />
            </Form.Item>
            <Form.Item name="constructionWorkEndDate" rules={[{ required: false }]} label="Дата завершення: ">
                <DatePicker />
            </Form.Item>
            <Form.Item name="commentOnWork" rules={[{ required: false }]} label="Коментар щодо виконаних робіт: ">
                <Input.TextArea rows={3} />
            </Form.Item>
        </>
    )
}