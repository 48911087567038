import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { notification } from "antd";
import { logout } from "../../features/authSlice";
import { prepareAuthHeader } from "./helpers";

const BASE_URL = process.env.REACT_APP_BASE_URL as string;

const baseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: prepareAuthHeader,
})

const customBaseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        notification.warning({
            message: 'Час сессії вийшов!',
            description: 'Будь ласка, авторизуйтесь повторно'
        })
        api.dispatch(logout());
    }
    return result;
}

export default customBaseQuery;