import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TerritoryUnitModel } from '../../models/TerritoryUnitModel';
import customBaseQuery from './common/customBaseQuery';
import { prepareAuthHeader } from './common/helpers';

const BASE_URL = process.env.REACT_APP_BASE_URL as string;

export const addressApi = createApi({
  reducerPath: 'addressApi',
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    getRays: builder.query<TerritoryUnitModel[], void>({
      query() {
        return {
          url: `business/rays`,
          method: 'GET',
        };
      },
    }),
    getCommunitiesByRayId: builder.query<TerritoryUnitModel[], string>({
      query(rayId) {
        return {
          url: `business/communities/${rayId}`,
          method: 'GET',
        };
      },
    }),
    getCitiesByCommunityId: builder.query<TerritoryUnitModel[], string>({
        query(communityId) {
          return {
            url: `business/cities/${communityId}`,
            method: 'GET',
          };
        },
      }),
  }),
});

export const {
  useGetRaysQuery,
  useGetCommunitiesByRayIdQuery,
  useGetCitiesByCommunityIdQuery,
} = addressApi;