import { Button, Col, Row, Table } from 'antd';
import { ColumnsType, TablePaginationConfig, TableProps } from 'antd/lib/table';
import { QueryOptions } from 'odata-query';
import { FC, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { RequestFiltersFields, RequestFiltersModel, RequestModel } from '../../models/RequestModel';
import { useGetRequestsQuery } from '../../redux/api/requestApi';
import RequestFilters from './components/RequestFilters';

const getColumns = (pathname: string): ColumnsType<RequestModel> => ([
  {
    title: '#',
    dataIndex: 'id',
    key: 'id',
    render: (id) => <Link to={`${pathname}/${id}`} >{id}</Link>
  },
  {
    title: 'Місто',
    dataIndex: ['city', 'short_name'],
    key: 'city',
  },
  {
    title: 'Громада',
    dataIndex: ['community', 'short_name'],
    key: 'community',
  },
  {
    title: 'Район',
    dataIndex: ['ray', 'short_name'],
    key: 'ray',
  },
  {
    title: 'Вулиця',
    dataIndex: 'street',
    key: 'street',
  },
  {
    title: 'Будинок',
    dataIndex: 'building',
    key: 'building',
  },
  {
    title: 'Тип будівлі',
    dataIndex: ['type', 'translations', 0, 'description'],
    key: 'type',
  },
  {
    title: 'Статус',
    dataIndex: ['status', 'translations', 0, 'description'],
    key: 'status',
  },
  {
    title: 'Категорія пошкоджень',
    dataIndex: ['damageCategory', 'translations', 0, 'description'],
    key: 'damageCategory',
  },
  {
    title: 'Тип документу правовласності',
    dataIndex: ['legalDocumentsType', 'translations', 0, 'description'],
    key: 'legalDocumentsType',
  },
  {
    title: 'Статус будівельних робіт',
    dataIndex: ['constructionWorkStatus', 'translations', 0, 'description'],
    key: 'constructionWorkStatus',
  },
]);

const getTopSkip = (pageSize: number = 0, currentPage: number = 0) => {
  const top = pageSize;
  const skip = (currentPage - 1) * pageSize;
  return { top, skip };
}

const defaultRequestQuery = {
  orderBy: 'createdDate desc',
  count: true,
}

const getRequestFiltersFromFormValues = (filters: RequestFiltersFields): RequestFiltersModel => ({
  'tolower(street)': filters.street ? {
    contains: filters.street,
  } : undefined,
  'tolower(building)': filters.building ? {
    contains: filters.building,
  } : undefined,
  status: filters.status,
  ray: filters.ray,
  community: filters.community,
  city: filters.city,
})

const Register: FC = () => {
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
  });

  const [filters, setFilters] = useState<RequestFiltersModel>({});
  const handleFilters = (filters: RequestFiltersFields) => {
    setPagination(currentpagination => ({ ...currentpagination, current: 1 }));
    setFilters(getRequestFiltersFromFormValues(filters));
  }

  const queryData = useMemo<Partial<QueryOptions<RequestModel>>>(() => ({    
    ...defaultRequestQuery,
    ...getTopSkip(pagination.pageSize, pagination.current),
    filter: filters,
  }), [pagination, filters]);

  const { data, isLoading, isFetching } = useGetRequestsQuery(queryData);
  const requests = data?.items;
  const count = data?.count;

  useEffect(() => {
    setPagination(currentPagination => ({...currentPagination, total: count }))
  }, [count]);

  const { pathname } = useLocation();
  
  const handleTableChange: TableProps<RequestModel>['onChange'] = (newPagination, _filters, _sorter) => {
    setPagination(newPagination);
  }

  return (
    <Row>
      <Col span={24}>
        <Row justify="end">
          <Link to={`${pathname}/new`}>
            <Button type="primary">Створити заявку</Button>
          </Link>
        </Row>
      </Col>
      <Col span={24}>
        <RequestFilters setFilters={handleFilters} />
      </Col>
      <Table<RequestModel>
        className="main-table"
        rowKey="id"
        loading={isLoading || isFetching}
        columns={getColumns(pathname)}
        dataSource={requests}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </Row>
  )
};

export default Register;
