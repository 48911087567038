import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import FormPageLayout from '../../components/common/FormPageLayout';
import RequestForm from '../../components/forms/requst-form/RequestForm';
import { useGetRequestQuery, useUpdateRequestMutation } from '../../redux/api/requestApi';

const EditRequest: FC = () => {
    const { requestId } = useParams();
    const { data: request, isLoading, isFetching } = useGetRequestQuery(requestId ?? skipToken, {
        refetchOnMountOrArgChange: true,
    });

    const [updateRequest, { isLoading: isUpdating }] = useUpdateRequestMutation();

    return (
        <FormPageLayout backButtonLabel="Реєстр">
            <RequestForm request={request || null} isLoading={isLoading || isFetching || isUpdating} onSubmitValues={updateRequest} />
        </FormPageLayout>
    );
};

export default EditRequest;
