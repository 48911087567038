import { createApi } from '@reduxjs/toolkit/query/react';
import { UserModel } from '../../models/UserModel';
import { logout, setAuthData, setChangePasswordData } from '../features/authSlice';
import customBaseQuery from './common/customBaseQuery';

interface LoginRequestPayload {
  email: string;
  password: string;
}

interface LoginResponsePayload {
  user: UserModel;
  access_token: string;
  refresh_token: string;
  token_type: string,
  expires_in: number;
  created_date: number;
}

interface TemporaryPasswordResponsePayload {
  isTemporaryPassword: true;
}

interface ChangePasswordRequestPayload {
  email: string;
  oldPassword: string;
  newPassword: string;
}

const isTemporaryPassResponse = (response: LoginResponsePayload | TemporaryPasswordResponsePayload): response is TemporaryPasswordResponsePayload => {
  return 'isTemporaryPassword' in response;
}

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    loginUser: builder.mutation<
      LoginResponsePayload | TemporaryPasswordResponsePayload,
      LoginRequestPayload
    >({
      query(data) {
        return {
          url: 'auth/token',
          method: 'POST',
          body: data,
          credentials: 'include',
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (isTemporaryPassResponse(data)) {
            dispatch(setChangePasswordData(args.email))
          } else {
            await dispatch(setAuthData({ user: data.user, token: data.access_token }));
          }
        } catch (error) { }
      },
    }),
    logoutUser: builder.mutation<void, void>({
      query() {
        return {
          url: 'auth/logout',
          method: 'PUT',
          credentials: 'include',
        };
      },
    }),
    changeUserPassword: builder.mutation<void, ChangePasswordRequestPayload>({
      query(data) {
        console.log(data);
        return {
          url: 'auth/password',
          method: 'PUT',
          credentials: 'include',
          body: data,
        };
      },
    }),
  }),
});

export const {
  useLoginUserMutation,
  useLogoutUserMutation,
  useChangeUserPasswordMutation,
} = authApi;