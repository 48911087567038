import { createApi } from '@reduxjs/toolkit/query/react';
import { CreateUpdateRequestModel, RequestModel } from '../../models/RequestModel';
import buildQuery, { QueryOptions } from 'odata-query';
import customBaseQuery from './common/customBaseQuery';

type GetRequestsWithCountResponse = {
  count: number;
  items: RequestModel[]
}

export type GetRequestsResponse = RequestModel[] | GetRequestsWithCountResponse;

export type RequestListResponce = {
  count: number;
  items: RequestModel[]
}

const isResponseWithCount = (response: GetRequestsResponse): response is GetRequestsWithCountResponse => {
  return !!(response as GetRequestsWithCountResponse).items 
}

export const requestApi = createApi({
  reducerPath: 'requestApi',
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    getRequest: builder.query<RequestModel, string | number>({
      query(requestId) {
        return {
          url: `business/request/${requestId}`,
          method: 'GET',
        };
      },
    }),
    getRequests: builder.query<GetRequestsWithCountResponse, Partial<QueryOptions<RequestModel>>>({
      query(queryData) {
        const queryString = buildQuery(queryData);
        return {
          url: queryString ? `business/request${queryString}` : `business/request`,
          method: 'GET',
        };
      },
      transformResponse: (response: GetRequestsResponse) => {
        return isResponseWithCount(response) ? response : {
          items: response,
          count: response.length,
        }
      },
      
    }),
    updateRequest: builder.mutation<RequestModel, CreateUpdateRequestModel>({
      query(data) {
        return {
          url: `business/request/${data.id}`,
          method: 'PUT',
          body: data
        };
      },
    }),
    createRequest: builder.mutation<RequestModel, CreateUpdateRequestModel>({
      query(data) {
        return {
          url: `business/request`,
          method: 'POST',
          body: data
        };
      },
    }),
  }),
});

export const {
  useGetRequestsQuery,
  useGetRequestQuery,
  useUpdateRequestMutation,
  useCreateRequestMutation,
} = requestApi;