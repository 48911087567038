import { DatePicker, Form, Input, UploadFile } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { FC } from "react";
import FileDraggerUpload from "../../common/FileDraggerUpload";

interface SurveyActFieldsProps {
    fileList: UploadFile[];
    onUploadChange: ((info: UploadChangeParam<UploadFile>) => void);
}

export const SurveyActFields: FC<SurveyActFieldsProps> = ({ onUploadChange, fileList }) => {
    return (
        <>
            <Form.Item>
                <h3>Інформація щодо документа про проведення попереднього обстеження (назва,реєстраційний номер, дата)</h3>
            </Form.Item>
            <Form.Item name="surveyAct" rules={[{ required: true }]} label="АКТ ОБСТЕЖЕННЯ No ">
                <Input />
            </Form.Item>
            <Form.Item name="surveyActDate" rules={[{ required: true }]} label=" від ">
                <DatePicker />
            </Form.Item>
            <Form.Item>
                <FileDraggerUpload fileList={fileList} onChange={onUploadChange}/>
            </Form.Item>
        </>
    )
}