import { RouterListModel } from '../../models/RouterListModel';
import Users from '../../pages/user/Users';
import Register from '../../pages/request/Request';
import Dashboard from '../../pages/Dashboard';
import { DashboardOutlined, FileSearchOutlined, UserOutlined } from '@ant-design/icons';
import EditRequest from '../../pages/request/EditRequest';
import CreateRequest from '../../pages/request/CreateRequest';
import EditUser from '../../pages/user/EditUser';
import CreateUser from '../../pages/user/CreateUser';

export const menuIcons: any = {
    register: <FileSearchOutlined />,
    users: <UserOutlined />,
    dashboard: <DashboardOutlined />
}

const RouteList: RouterListModel[] = [
    {
        path: 'registers',
        code: 'registers',
        component: Register,
        icon: 'register',
        name: 'Реєстр об`єктів',
    },
    {
        path: 'registers/:requestId',
        code: 'edit_registers',
        component: EditRequest,
    },
    {
        path: 'registers/new',
        code: 'create_registers',
        component: CreateRequest,
    },
    {
        path: 'users',
        code: 'users',
        component: Users,
        icon: 'users',
        name: 'Користувачі',
    },
    {
        path: 'users/:userId',
        code: 'edit_user',
        component: EditUser,
    },
    {
        path: 'users/new',
        code: 'create_user',
        component: CreateUser,
    },
    {
        path: 'dashboard',
        code: 'dashboard',
        component: Dashboard,
        icon: 'dashboard',
        name: 'Загальна панель'
    },
];

export default RouteList;
