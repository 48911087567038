import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Space } from "antd";
import { FC } from "react";


export const ApplicantsFields: FC= () => {
    return (
        <>
            <Form.Item>
                <h3>Власник</h3>
            </Form.Item>
            <Form.List name="applicants">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                <Form.Item
                                    {...restField}
                                    name={[name, 'surname']}
                                    rules={[{ required: true }]}
                                >
                                    <Input placeholder="Прізвище" />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'name']}
                                    rules={[{ required: true }]}
                                >
                                    <Input placeholder="Ім’я" />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'patronymic']}
                                    rules={[{ required: false }]}
                                >
                                    <Input placeholder="По Батькові" />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'email']}
                                    rules={[{ required: false }]}
                                >
                                    <Input placeholder="Е-mail" />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'phone']}
                                    rules={[{ required: true }]}
                                >
                                    <Input placeholder="Номер телефону" />
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(name)} />
                            </Space>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Додати співвласника
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </>
    )
}