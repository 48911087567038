import { Form, Input } from "antd";
import { FC } from "react";

interface UserDataFieldsProps {
    disableEmail?: boolean;
}

const UserDataFields: FC<UserDataFieldsProps> = ({ disableEmail }) => {

    return (
        <>
            <Form.Item name="email" rules={[{ required: true }]}>
                <Input placeholder="Ім’я користувача" disabled={disableEmail}/>
            </Form.Item>
            <Form.Item name="phone">
                <Input placeholder="Телефон" />
            </Form.Item>
            <Form.Item name="firstName" rules={[{ required: true }]}>
                <Input placeholder="Ім’я" />
            </Form.Item>
            <Form.Item name="lastName">
                <Input placeholder="Прізвище" />
            </Form.Item>
        </>
    );
}

export default UserDataFields;