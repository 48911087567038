import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ConstructionWorkStatuses } from '../../constants/enums/ConstructionWorkStatuses';
import { DamageCategories } from '../../constants/enums/DemageCategories';
import { LegalDocumentsType } from '../../constants/enums/LegalDocumentsType';
import { RequestStatuses } from '../../constants/enums/RequestStatuses';
import { RequestTypes } from '../../constants/enums/RequestTypes';
import { SurveyActRecommendationCodes } from '../../constants/enums/SurveyActRecommendationCodes';
import { CommonDictionaryModel } from '../../models/CommonDictionaryModel';
import customBaseQuery from './common/customBaseQuery';
import { prepareAuthHeader } from './common/helpers';

const BASE_URL = process.env.REACT_APP_BASE_URL as string;

export const dictionariesApi = createApi({
  reducerPath: 'dictionariesApi',
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    getLegalDocumentTypes: builder.query<CommonDictionaryModel<LegalDocumentsType>[], void>({
      query() {
        return {
          url: `business/dictionary/legal_document_types`,
          method: 'GET',
        };
      },
    }),
    getDamageCategories: builder.query<CommonDictionaryModel<DamageCategories>[], void>({
        query() {
          return {
            url: `business/dictionary/damage_categories`,
            method: 'GET',
          };
        },
      }),
      getRequestTypes: builder.query<CommonDictionaryModel<RequestTypes>[], void>({
        query() {
          return {
            url: `business/dictionary/request_types`,
            method: 'GET',
          };
        },
      }),
      getSurveyActRecommendations: builder.query<CommonDictionaryModel<SurveyActRecommendationCodes>[], void>({
        query() {
          return {
            url: `business/dictionary/survey_act_recommendations`,
            method: 'GET',
          };
        },
      }),
      getConstructionWorkStatuses: builder.query<CommonDictionaryModel<ConstructionWorkStatuses>[], void>({
        query() {
          return {
            url: `business/dictionary/construction_works_statuses`,
            method: 'GET',
          };
        },
      }),
      getRequestStatuses: builder.query<CommonDictionaryModel<RequestStatuses>[], void>({
        query() {
          return {
            url: `business/dictionary/request_statuses`,
            method: 'GET',
          };
        },
      }),
  }),
});

export const {
  useGetLegalDocumentTypesQuery,
  useGetDamageCategoriesQuery,
  useGetRequestTypesQuery,
  useGetSurveyActRecommendationsQuery,
  useGetConstructionWorkStatusesQuery,
  useGetRequestStatusesQuery,
} = dictionariesApi;