import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import FormPageLayout from '../../components/common/FormPageLayout';
import UpdateUserForm from '../../components/forms/user-form/UpdateUserForm';
import { useGetUserQuery, useUpdateUserMutation } from '../../redux/api/usersApi';

const EditUser: FC = () => {
    const { userId } = useParams();
    const { data: user, isLoading, isFetching } = useGetUserQuery(userId ?? skipToken, {
        refetchOnMountOrArgChange: true,
    });

    const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();

    return (
            <FormPageLayout backButtonLabel="Користувачі">
                <UpdateUserForm user={user || null} isLoading={isLoading || isFetching || isUpdating} onSubmitValues={updateUser} />
            </FormPageLayout>
    );
};

export default EditUser;
