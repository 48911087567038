import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Col, Dropdown, Layout, Menu, Row } from 'antd';
import { MenuProps } from 'rc-menu';
import { FC } from 'react';
import { HeaderModel } from '../../models/HeaderModel';
import { logout } from '../../redux/features/authSlice';
import { useAppDispatch } from '../../redux/hooks';

const { Header: AntHeader } = Layout;

const LOGOUT_KEY = 'logout';

const UserMenu: FC = () => {
    const dispatch = useAppDispatch();

    const handleLogout = () => {
        dispatch(logout());
    }

    const handleMenuClick: MenuProps['onClick'] = (info) => {
        if(info.key === LOGOUT_KEY) 
            handleLogout();
    }

    return (
        <Menu
            onClick={handleMenuClick}
            items={[
                {
                    label: 'Профіль',
                    key: 'profile',
                    icon: <UserOutlined />
                },
                {
                    type: 'divider',
                },
                {
                    label: 'Вихід',
                    key: LOGOUT_KEY,
                    icon: <LogoutOutlined />
                },
            ]}
        />
    )
};

const Header: FC<HeaderModel> = ({ className, style }: HeaderModel) => {
    return (
        <AntHeader className={className} style={style}>
            <Row>
                <Col span={4} order={1}></Col>
                <Col span={16} order={2}></Col>
                <Col span={4} order={3} style={{ textAlign: 'end' }}>
                    <Dropdown overlay={<UserMenu />} trigger={['click']}>
                        <Avatar size="large" icon={<UserOutlined />} style={{ marginRight: '25px' }} />
                    </Dropdown>
                </Col>
            </Row>
        </AntHeader>
    );
};

export default Header;
