import {
    Checkbox,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Radio,
    Select,
    UploadFile,
} from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { FC } from "react";
import { ConstructionWorkStatuses } from "../../../constants/enums/ConstructionWorkStatuses";
import { DamageCategories } from "../../../constants/enums/DemageCategories";
import { SurveyActRecommendationCodes } from "../../../constants/enums/SurveyActRecommendationCodes";
import { CommonDictionaryModel } from "../../../models/CommonDictionaryModel";
import { TerritoryUnitModel } from "../../../models/TerritoryUnitModel";
import FileDraggerUpload from "../../common/FileDraggerUpload";
import { LocationFields } from "./LocationFields";
import { getSelectOptionFromDictionary } from "./RequestForm";
import { TechnicalReportFields } from "./TechnicalReportFields";

interface MultiApartmentBuildingFieldsProps {
    rays?: TerritoryUnitModel[];
    communities?: TerritoryUnitModel[];
    cities?: TerritoryUnitModel[];
    damageCategories?: CommonDictionaryModel<DamageCategories>[];
    surveyActRecommendations?: CommonDictionaryModel<SurveyActRecommendationCodes>[];
    constructionWorkStatuses?: CommonDictionaryModel<ConstructionWorkStatuses>[];
    isDismantling: boolean;
    handleRaySelect: (unit: TerritoryUnitModel['id']) => void;
    handleCommunitySelect: (unit: TerritoryUnitModel['id']) => void;
    handleSurveyActRecommendationChange: (value: CommonDictionaryModel<SurveyActRecommendationCodes>['code']) => void;
    technicalReportFiles:  UploadFile[];
    onTechnicalReportFilesUploadChange: (info: UploadChangeParam<UploadFile<any>>) => void;
    buildingFiles:  UploadFile[];
    onBuildingFilesUploadChange: (info: UploadChangeParam<UploadFile<any>>) => void;
}

export const MultiApartmentBuildingFields: FC<MultiApartmentBuildingFieldsProps> = ({
    rays,
    communities,
    cities,
    handleRaySelect,
    handleCommunitySelect,
    handleSurveyActRecommendationChange,
    damageCategories,
    surveyActRecommendations,
    constructionWorkStatuses,
    isDismantling,
    technicalReportFiles,
    onTechnicalReportFilesUploadChange,
    buildingFiles,
    onBuildingFilesUploadChange,
}) => {
    return (
        <>
            <LocationFields
                rays={rays}
                communities={communities}
                cities={cities}
                handleRaySelect={handleRaySelect}
                handleCommunitySelect={handleCommunitySelect}
            />
            <TechnicalReportFields
                fileList={technicalReportFiles}
                onUploadChange={onTechnicalReportFilesUploadChange} 
            />
            <Form.Item name="floors" rules={[{ required: false }]} label="Поверховість будинку">
                <InputNumber placeholder="поверхів"/>
            </Form.Item>
            <Form.Item name="numberOfAppartments" rules={[{ required: false }]} label="Кількість квартир">
                <InputNumber placeholder="шт"/>
            </Form.Item>
            <Form.Item name="totalArea" rules={[{ required: false }]} label="Площа">
                <InputNumber placeholder="м2" />
            </Form.Item>
            <Form.Item name="damageCategory" rules={[{ required: true }]} label="Категорія пошкоджень відповідно до «Акту комісії»:">
                <Radio.Group>
                    {damageCategories?.map(category => <Radio value={category.code}>{category.translations[0].description}</Radio>)}
                </Radio.Group>
            </Form.Item>
            <Form.Item name="surveyActRecommendation" rules={[{ required: false }]} label="Рекомендовано відповідно до «Акту комісії» виконання робіт: ">
                <Select onChange={handleSurveyActRecommendationChange}>
                    {surveyActRecommendations?.map(getSelectOptionFromDictionary)}
                </Select>
            </Form.Item>
            <Form.Item name="additionalInfo" rules={[{ required: false }]} label="Додаткова інформація">
                <Input.TextArea rows={3} />
            </Form.Item>
            <Form.Item label="Додати файл: ">
                <FileDraggerUpload fileList={buildingFiles} onChange={onBuildingFilesUploadChange} />
            </Form.Item>
            <Form.Item>
                <h3>Статус об’єкта</h3>
            </Form.Item>
            <Form.Item name="commissionAct" valuePropName="checked" rules={[{ required: false }]} label="Cкладено акт комісії: ">
                <Checkbox />
            </Form.Item>
            <Form.Item name="decisionOfTBTNC" label="Рішення комісії ТБТНС">
                <Input disabled={!isDismantling} />
            </Form.Item>
            <Form.Item name="isPKDForDismantiling" valuePropName="checked" rules={[{ required: false }]} label="ПКД на демонтаж розроблена: ">
                <Checkbox disabled={!isDismantling} />
            </Form.Item>
            <Form.Item name="PKDForDismantiling" rules={[{ required: false }]} label="Вартість будівельних робіт з демонтажу, тис. грн">
                <InputNumber disabled={!isDismantling} />
            </Form.Item>
            <Form.Item name="contractForDismantiling" valuePropName="checked" rules={[{ required: false }]} label="Складено договір на демонтаж: ">
                <Checkbox disabled={!isDismantling} />
            </Form.Item>
            <Form.Item name="dateOfDismantiling" rules={[{ required: false }]} label="Дата демонтажу">
                <DatePicker disabled={!isDismantling} />
            </Form.Item>
            <Form.Item name="contractForPKD" valuePropName="checked" rules={[{ required: false }]} label="Складено договір на розроблення ПКД: ">
                <Checkbox disabled={!isDismantling} />
            </Form.Item>
            <Form.Item name="dateOfEndPKD" rules={[{ required: false }]} label="Орієнтовні терміни завершення">
                <DatePicker disabled={!isDismantling} />
            </Form.Item>
            <Form.Item name="approvedPKD" valuePropName="checked" rules={[{ required: false }]} label="ПКД затверджено: ">
                <Checkbox disabled={!isDismantling} />
            </Form.Item>
            <Form.Item name="approvedDatePKD" rules={[{ required: false }]} label="Дата затвердження">
                <DatePicker disabled={!isDismantling} />
            </Form.Item>
            <Form.Item name="costOfConstructionWorks" rules={[{ required: false }]} label="Вартість будівельних робіт, тис. грн">
                <InputNumber />
            </Form.Item>
            <Form.Item name="concludedContractForWork" valuePropName="checked" rules={[{ required: false }]} label="Заключений договір на виконання робіт: ">
                <Checkbox />
            </Form.Item>
            <Form.Item name="concludedContractEndWorkDate" rules={[{ required: false }]} label="Орієнтовні терміни завершення">
                <DatePicker />
            </Form.Item>
            <Form.Item name="constructionWorkStatus" rules={[{ required: false }]} label="Статус будівельних робіт: ">
                <Select>
                    {constructionWorkStatuses?.map(getSelectOptionFromDictionary)}
                </Select>
            </Form.Item>
            <Form.Item name="constructionWorkStartDate" rules={[{ required: false }]} label="Дата початку: ">
                <DatePicker />
            </Form.Item>
            <Form.Item name="constructionWorkEndDate" rules={[{ required: false }]} label="Дата завершення: ">
                <DatePicker />
            </Form.Item>
            <Form.Item name="commentOnWork" rules={[{ required: false }]} label="Коментар щодо виконаних робіт: ">
                <Input.TextArea rows={3} />
            </Form.Item>
        </>
    )
}