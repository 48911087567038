import { Button, Form, Input, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { FC } from "react";
import { CreateUserModel } from "../../../models/UserModel";
import UserDataFields from "./UserDataFields";

interface UserFormProps {
    isLoading: boolean;
    onSubmitValues: ((data: CreateUserModel) => void);
}

interface UserFormValues extends CreateUserModel { }

const CreateUserForm: FC<UserFormProps> = ({ isLoading, onSubmitValues }) => {
    const [form] = useForm<UserFormValues>();

    const onFinish = (values: UserFormValues) => {
        const finalUser: CreateUserModel = {
            ...values,
        }
        onSubmitValues(finalUser);
    }

    return (
        <Spin spinning={isLoading}>
            <Form<UserFormValues>
                form={form}
                onFinish={onFinish}
                validateMessages={{
                    required: 'Обов’язкове поле!',
                }}
            >
                <UserDataFields />
                <Form.Item
                    name="password"
                    label="Тимчасовий пароль"
                    rules={[{ required: true }]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="confirm"
                    label="Підтвердіть пароль"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Паролі не співпадають!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Зберегти
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
}

export default CreateUserForm;