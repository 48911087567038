import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserModel } from '../../models/UserModel';
import { RootState } from '../store';

interface IUserState {
  user: UserModel | null;
  token: string | null;
  changePasswordData?: {
    isTemporaryPass: true;
    email: string;
  };
}

const initialState: IUserState = {
  user: null,
  token: null,
};

export const authSlice = createSlice({
  initialState,
  name: 'authSlice',
  reducers: {
    logout: () => initialState,
    setAuthData: (_state, action: PayloadAction<{ user: UserModel, token: string }>) => action.payload,
    setChangePasswordData: (state, action: PayloadAction<string>) => ({...state, changePasswordData: { isTemporaryPass: true, email: action.payload }})
  },
});

export default authSlice.reducer;

export const {
  logout,
  setAuthData,
  setChangePasswordData,
} = authSlice.actions;

export const getUser = (state: RootState) => state.auth.user;
export const getChangePasswordData = (state: RootState) => state.auth.changePasswordData;