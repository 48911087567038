import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Button, Form, RadioChangeEvent, Select, Spin, UploadFile } from "antd";
import { useForm } from "antd/lib/form/Form";
import { UploadChangeParam } from "antd/lib/upload";
import moment from "moment";
import { FC, useCallback, useEffect, useState } from "react";
import { RequestStatuses } from "../../../constants/enums/RequestStatuses";
import { RequestTypes } from "../../../constants/enums/RequestTypes";
import { SurveyActRecommendationCodes } from "../../../constants/enums/SurveyActRecommendationCodes";
import { CommonDictionaryModel } from "../../../models/CommonDictionaryModel";
import { CreateUpdateRequestModel, RequestModel } from "../../../models/RequestModel";
import { TerritoryUnitModel } from "../../../models/TerritoryUnitModel";
import {
    useGetCitiesByCommunityIdQuery,
    useGetCommunitiesByRayIdQuery,
    useGetRaysQuery
} from "../../../redux/api/addressApi";
import { 
    useGetConstructionWorkStatusesQuery,
    useGetDamageCategoriesQuery,
    useGetLegalDocumentTypesQuery,
    useGetRequestStatusesQuery,
    useGetRequestTypesQuery,
    useGetSurveyActRecommendationsQuery
} from "../../../redux/api/dictionariesApi";
import { FileMetaDataModel, useDeleteFileMutation, useLazyGetFileDataByIdQuery } from "../../../redux/api/filesApi";
import { IndividualBuildingFields } from "./IndividualBuildingFields";
import { MultiApartmentBuildingFields } from "./MultiApartmentBuildingFields";

interface RequestFormProps {
    request: RequestModel | null;
    isLoading: boolean;
    onSubmitValues: (data: CreateUpdateRequestModel) => void;
}

interface RequestFormValues extends Omit<
    CreateUpdateRequestModel, 
    'concludedContractEndWorkDate' | 
    'constructionWorkStartDate' | 
    'constructionWorkEndDate' | 
    'surveyActDate' | 
    'technicalReportDate' |
    'approvedDatePKD' | 
    'dateOfEndPKD' |
    'dateOfDismantiling'
>{
    concludedContractEndWorkDate?: moment.Moment | null;
    constructionWorkStartDate?: moment.Moment | null;
    constructionWorkEndDate?: moment.Moment | null;
    surveyActDate?: moment.Moment | null;
    technicalReportDate?: moment.Moment | null;
    approvedDatePKD?: moment.Moment | null;
    dateOfEndPKD?: moment.Moment | null;
    dateOfDismantiling?: moment.Moment | null;
}


export const getSelectOptionFromDictionary = (item: CommonDictionaryModel) => <Select.Option key={item.code} value={item.code}>{item.translations[0].description}</Select.Option>

export type FileUploadResponse = { id: string }[];
export interface UploadFileWithId<T = any> extends UploadFile<T> { 
    id?: string;
};

function isString(value: string | undefined): value is string {
    return !!value;
}

const getUploadFileWithIdFromFileMetaData = (fileData: FileMetaDataModel): UploadFileWithId => ({
    ...fileData,
    uid: fileData.id,
    status: 'done', // DOWNLOAD ICON IS NOT RENDERED WITHOUT THIS PROPERTY
})

const RequestForm: FC<RequestFormProps> = ({ isLoading, request, onSubmitValues }) => {
    const [form] = useForm<RequestFormValues>();

    const [currentType, setCurrentType] = useState<CommonDictionaryModel<RequestTypes>['code']>();

    const { data: rays } = useGetRaysQuery();
    const [currentRay, setCurrentRay] = useState<TerritoryUnitModel['id']>();
    const { data: communities } = useGetCommunitiesByRayIdQuery(currentRay ?? skipToken);
    const [currentCommunity, setCurrentCommunity] = useState<TerritoryUnitModel['id']>();
    const { data: cities } = useGetCitiesByCommunityIdQuery(currentCommunity ?? skipToken);

    const { data: legalDocumentTypes } = useGetLegalDocumentTypesQuery();
    const { data: damageCategories } = useGetDamageCategoriesQuery();
    const { data: surveyActRecommendations } = useGetSurveyActRecommendationsQuery();
    const { data: constructionWorkStatuses } = useGetConstructionWorkStatusesQuery();
    const { data: requestTypes } = useGetRequestTypesQuery();
    const { data: requestStatuses } = useGetRequestStatusesQuery();

    const [triggerGetFileDataById] = useLazyGetFileDataByIdQuery();

    const getFilesDataFromIds = useCallback(async (fileIdArr: string[]) => {
        const filesPromiseArray = await Promise.allSettled(fileIdArr.map((id => triggerGetFileDataById(id).unwrap())));
        const files: FileMetaDataModel[] = [];
        filesPromiseArray.forEach(settledPromiseFile => {
            if (settledPromiseFile.status === 'fulfilled') {
                files.push(settledPromiseFile.value);
            } else {
                console.error(settledPromiseFile.reason.data?.message || 'Error while getting file data');
            }
        })
        return files;
    }, [triggerGetFileDataById]);

    const fetchFiles = useCallback(async ({ technicalReportFiles, surveyActFiles, buildingFiles }: RequestModel) => {
            if(technicalReportFiles) {
                getFilesDataFromIds(technicalReportFiles)
                    .then(files => setTechnicalReportFiles(files.map(getUploadFileWithIdFromFileMetaData)));
            }

            if(surveyActFiles) {
                getFilesDataFromIds(surveyActFiles)
                    .then(files => setSurveyActFiles(files.map(getUploadFileWithIdFromFileMetaData)));
            }

            if(buildingFiles) {
                getFilesDataFromIds(buildingFiles)
                    .then(files => setBuildingFiles(files.map(getUploadFileWithIdFromFileMetaData)));
            }
    }, [getFilesDataFromIds]);
    
    useEffect(() => {
        if (request) {
            form.setFieldsValue({
                ...request,
                ray: request.ray.id,
                community: request.community.id,
                city: request.city.id,
                surveyActDate: request.surveyActDate ? moment(request.surveyActDate) : null,
                damageCategory: request.damageCategory?.code,
                surveyActRecommendation: request.surveyActRecommendation?.code,
                concludedContractEndWorkDate: request.concludedContractEndWorkDate ? moment(request.concludedContractEndWorkDate) : null,
                constructionWorkStartDate: request.constructionWorkStartDate ? moment(request.constructionWorkStartDate) : null,
                constructionWorkEndDate: request.constructionWorkEndDate ? moment(request.constructionWorkEndDate) : null,
                technicalReportDate: request.technicalReportDate ? moment(request.technicalReportDate) : null,
                constructionWorkStatus: request.constructionWorkStatus?.code,
                legalDocumentsType: request.legalDocumentsType?.code,
                approvedDatePKD: request.approvedDatePKD ? moment(request.approvedDatePKD) : null,
                dateOfEndPKD: request.dateOfEndPKD ? moment(request.dateOfEndPKD) : null,
                dateOfDismantiling: request.dateOfDismantiling ? moment(request.dateOfDismantiling) : null,
            })
            setCurrentType(request.type.code);
            setCurrentRay(request.ray.id);
            setCurrentCommunity(request.community.id);
            setNeedForBasciExamination(request.needForBasicExamination);
            setCurrentSurveyActRecommendation(request.surveyActRecommendation?.code);
        }
    }, [request, form, fetchFiles]);

    useEffect(() => {
        if(request)
            fetchFiles(request);
    }, [request, fetchFiles])

    const handleTypeChange = (value: CommonDictionaryModel<RequestTypes>['code']) => {
        setCurrentType(value);
    }

    const handleRaySelect = (value: TerritoryUnitModel['id']) => {
        setCurrentRay(value);
        setCurrentCommunity(undefined);
        form.setFieldsValue({
            community: undefined,
            city: undefined,
        })
    }

    const handleCommunitySelect = (value: TerritoryUnitModel['id']) => {
        setCurrentCommunity(value);
        form.setFieldsValue({
            city: undefined,
        })
    }

    const [currentSurveyActRecommendation, setCurrentSurveyActRecommendation] = useState<SurveyActRecommendationCodes>();
    const handleSurveyActRecommendationChange = (value: SurveyActRecommendationCodes) => {
        setCurrentSurveyActRecommendation(value)
    }

    const [needForBasicExamination, setNeedForBasciExamination] = useState<boolean>();
    const handleNeedForBasicExaminationChange = (e: RadioChangeEvent) => {
        setNeedForBasciExamination(e.target.value);
    }

    const [deleteFile] = useDeleteFileMutation();
    const [surveyActFiles, setSurveyActFiles] = useState<UploadFileWithId[]>([]);
    const [technicalReportFiles, setTechnicalReportFiles] = useState<UploadFileWithId[]>([]);
    const [buildingFiles, setBuildingFiles] = useState<UploadFileWithId[]>([]);
    const handleFilesChange = (setFiles: React.Dispatch<React.SetStateAction<UploadFileWithId<any>[]>>) => (info: UploadChangeParam<UploadFileWithId<FileUploadResponse>>) => {
        if(info.file.status === 'done' && info.file.response) {
            setFiles(info.fileList.map(file => file.uid === info.file.uid ? {...file, id: (info.file.response as FileUploadResponse)[0]?.id} : file));
            return;
        } 
        if(info.file.status === 'removed' && info.file.id) {
            deleteFile(info.file.id);
        }
        setFiles(info.fileList);
    }

    const onFinish = (values: RequestFormValues) => {
        const finalRequest: CreateUpdateRequestModel = {
            ...values,
            id: request?.id,
            applicants: values.applicants || [],
            surveyActDate: values.surveyActDate?.toISOString() || null,
            concludedContractEndWorkDate: values.concludedContractEndWorkDate?.toISOString() || null,
            constructionWorkStartDate: values.constructionWorkStartDate?.toISOString() || null,
            constructionWorkEndDate: values.constructionWorkEndDate?.toISOString() || null,
            technicalReportDate: values.technicalReportDate?.toISOString() || null,
            dateOfDismantiling: values.dateOfDismantiling?.toISOString() || null,
            dateOfEndPKD: values.dateOfEndPKD?.toISOString() || null,
            approvedDatePKD: values.approvedDatePKD?.toISOString() || null,
            technicalReportFiles: technicalReportFiles.map((file) => file.id).filter<string>(isString),
            surveyActFiles: surveyActFiles.map((file) => file.id).filter<string>(isString),
            buildingFiles: buildingFiles.map((file) => file.id).filter<string>(isString)
        }
        onSubmitValues(finalRequest);
    }

    const isIndividualBuilding = currentType === RequestTypes.individualBuilding;
    const isDismantling = currentSurveyActRecommendation === SurveyActRecommendationCodes.dismantling;
    return (
        <Spin spinning={isLoading}>
            <Form<RequestFormValues>
                form={form}
                initialValues={{
                    status: {
                        code: RequestStatuses.new,
                    }
                }}
                onFinish={onFinish}
                validateMessages={{
                    required: 'Обов’язкове поле!',
                }}
            >
                <Form.Item
                    name={['type', 'code']}
                    label="Тип будівлі: "
                    rules={[{ required: true }]}
                >
                    <Select<CommonDictionaryModel<RequestTypes>['code']>
                        onChange={handleTypeChange}
                    >
                        {requestTypes?.map(getSelectOptionFromDictionary)}
                    </Select>
                </Form.Item>
                <Form.Item
                    name={['status', 'code']}
                    label="Статус: "
                    rules={[{ required: true }]}
                >
                    <Select<CommonDictionaryModel<RequestStatuses>['code']>>
                        {requestStatuses?.map(getSelectOptionFromDictionary)}
                    </Select>
                </Form.Item>
                {isIndividualBuilding ?
                    <IndividualBuildingFields
                        surveyActFiles={surveyActFiles}
                        onSurveyActFilesUploadChange={handleFilesChange(setSurveyActFiles)}
                        onTechnicalReportFilesUploadChange={handleFilesChange(setTechnicalReportFiles)}
                        technicalReportFiles={technicalReportFiles as UploadFile[]}
                        handleCommunitySelect={handleCommunitySelect}
                        handleNeedForBasicExaminationChange={handleNeedForBasicExaminationChange}
                        handleRaySelect={handleRaySelect}
                        rays={rays}
                        communities={communities}
                        cities={cities}
                        damageCategories={damageCategories}
                        constructionWorkStatuses={constructionWorkStatuses}
                        legalDocumentTypes={legalDocumentTypes}
                        needForBasicExamination={needForBasicExamination}
                        surveyActRecommendations={surveyActRecommendations}
                        onBuildingFilesUploadChange={handleFilesChange(setBuildingFiles)}
                        buildingFiles={buildingFiles}
                    />
                    :
                    <MultiApartmentBuildingFields
                        onTechnicalReportFilesUploadChange={handleFilesChange(setTechnicalReportFiles)}
                        technicalReportFiles={technicalReportFiles}
                        handleCommunitySelect={handleCommunitySelect}
                        handleRaySelect={handleRaySelect}
                        rays={rays}
                        communities={communities}
                        cities={cities}
                        damageCategories={damageCategories}
                        constructionWorkStatuses={constructionWorkStatuses}
                        surveyActRecommendations={surveyActRecommendations}
                        isDismantling={isDismantling}
                        handleSurveyActRecommendationChange={handleSurveyActRecommendationChange}
                        onBuildingFilesUploadChange={handleFilesChange(setBuildingFiles)}
                        buildingFiles={buildingFiles}
                    />
                }
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Зберегти
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
}

export default RequestForm;