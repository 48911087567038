import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import customBaseQuery from './common/customBaseQuery';
import { prepareAuthHeader } from './common/helpers';

const BASE_URL = process.env.REACT_APP_BASE_URL as string

export interface FileMetaDataModel {
    contentType: string;
    id: string;
    name: string;
}

export const filesApi = createApi({
    reducerPath: 'filesApi',
    baseQuery: customBaseQuery,
    endpoints: (builder) => ({
        getFileDataById: builder.query<FileMetaDataModel, string>({
            query(fileId) {
                return {
                    url: `storage/files/${fileId}/metadata`,
                    method: 'GET',
                };
            },
        }),
        deleteFile: builder.mutation<void, string>({
            query(fileId) {
                return {
                    url: `storage/files/${fileId}`,
                    method: 'DELETE',
                };
            },
        }),
    }),
});

// Getting file is not implemented by Redux Toolkit Query API
// to prevent placing file in the cache
// see: https://github.com/reduxjs/redux-toolkit/issues/1522
export const getFileById = (id: string) => {
    return fetch(`${BASE_URL}storage/files/${id}`).then(response => response.blob());
}

export const {
    useGetFileDataByIdQuery,
    useLazyGetFileDataByIdQuery,
    useDeleteFileMutation,
} = filesApi;



