import { DeleteOutlined, DownloadOutlined, InboxOutlined } from "@ant-design/icons";
import { Upload, UploadProps } from "antd";
import { FC } from "react";
import { getFileById } from "../../redux/api/filesApi";
import { UploadFileWithId } from "../forms/requst-form/RequestForm";

const fileApiLink = `${process.env.REACT_APP_BASE_URL}storage/files`;
const downloadFile = (file: UploadFileWithId) => {
    if(file.id){
        getFileById(file.id)
            .then(blob => {
                const objectUrl = window.URL.createObjectURL(blob);
                const anchor = document.createElement("a");

                document.body.appendChild(anchor);
                anchor.href = objectUrl;
                anchor.download = file.name;
                anchor.click();

                window.URL.revokeObjectURL(objectUrl);
                document.body.removeChild(anchor);
            })
    } 
}

const FileDraggerUpload: FC<UploadProps> = (props) => {
    return (
        <Upload.Dragger
            name="file"
            action={fileApiLink}
            onChange={props.onChange}
            fileList={props.fileList}
            onDownload={(file: UploadFileWithId) => {
                downloadFile(file);
            }}
            showUploadList={{
                showDownloadIcon: true,
                downloadIcon: <DownloadOutlined />,
                showRemoveIcon: true,
                removeIcon: <DeleteOutlined />,
            }}
            {...props}
        >
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">Обрати/Додати файл<br />(перетягнути сюди)</p>
            <p className="ant-upload-hint">
                {/*
                                    TO DO:
                                    ADD WHEN VALIDATION VILL BE SUPPORTED

                                    <ul>
                                        <li>.pdf, .jgp, .png, .doc/x;</li>
                                        <li>загальна кількість до 20;</li>
                                        <li>вага одного файла до 10 МБ.</li>
                                    </ul> 
                                */}
            </p>
        </Upload.Dragger>
    )
}

export default FileDraggerUpload;