import { Button, Card, Col, Form, Input, notification, Row, Spin } from 'antd';
import { FC, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useChangeUserPasswordMutation } from '../redux/api/authApi';
import { getChangePasswordData, logout } from '../redux/features/authSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';

interface ChangePasswordValues {
    email: string;
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

const ChangePassword: FC = () => {
    const [changeUserPassword, { isLoading, error, isSuccess }] = useChangeUserPasswordMutation();

    const onFinish = (values: ChangePasswordValues) => {
        changeUserPassword({
            email: values.email,
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
        });
    };

    const dispatch = useAppDispatch();
    useEffect(() => {
        if(isSuccess) {
            notification.success({
                message: 'Пароль успішно оновлено',
                description: 'Авторизуйтесь, використовуючи новий пароль',
            });
            dispatch(logout());
        }
    }, [isSuccess, dispatch]);

    useEffect(() => {
        if (error && 'data' in error) {
            notification.error({
                message: (error.data as any).message,
            })
        }
    }, [error]);

    const changePassworData = useAppSelector(getChangePasswordData);
    if(!changePassworData) {
        return <Navigate to={'/'} />
    }

    return (
        <Row justify="center" align="middle" style={{ height: '100%' }}>
            <Col>
                <Spin spinning={isLoading}>
                    <Card style={{ width: '400px' }}>
                        <Form<ChangePasswordValues>
                            initialValues={{
                                email: changePassworData.email,
                            }}
                            name="basic"
                            layout="vertical"
                            onFinish={onFinish}
                            autoComplete="off">
                            <Form.Item
                                label="Ім’я користувача"
                                name="email"
                                rules={[{ required: true, message: `Обов'язкове поле!` }]}>
                                <Input disabled />
                            </Form.Item>
                            <Form.Item
                                name="oldPassword"
                                label="Старий тимчасовий пароль"
                                rules={[{ required: true }]}
                                hasFeedback
                            >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item
                                name="newPassword"
                                label="Новий пароль"
                                rules={[{ required: true }]}
                                hasFeedback
                            >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item
                                name="confirmPassword"
                                label="Підтвердіть пароль"
                                dependencies={['newPassword']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('newPassword') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Паролі не співпадають!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Підтвердити
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Spin>
            </Col>
        </Row>
    );
};

export default ChangePassword;
